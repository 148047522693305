import React, { useState, useEffect } from "react";

export default function Feedback() {
  // Start with undefined to prevent flash
  const [showButton, setShowButton] = useState(undefined);

  useEffect(() => {
    const checkStoredState = () => {
      const storedData = localStorage.getItem("feedbackBannerState");
      if (storedData) {
        const { hidden, timestamp } = JSON.parse(storedData);
        const oneDayInMs = 24 * 60 * 60 * 1000;
        const now = new Date().getTime();

        // Show banner if more than 1 day has passed
        if (now - timestamp > oneDayInMs) {
          localStorage.removeItem("feedbackBannerState");
          setShowButton(true);
        } else {
          setShowButton(!hidden);
        }
      } else {
        // No stored state, show the banner
        setShowButton(true);
      }
    };

    // Check immediately on mount
    checkStoredState();
  }, []);

  const handleDismiss = () => {
    const stateData = {
      hidden: true,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem("feedbackBannerState", JSON.stringify(stateData));
    setShowButton(false);
  };

  // Don't render anything while loading
  if (showButton === undefined) {
    return null;
  }

  return (
    <>
      {showButton ? (
        <>
          <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
            <div
              aria-hidden="true"
              className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            >
              <div
                style={{
                  clipPath:
                    "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                }}
                className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#3498db] to-[#58d68d] opacity-70"
              />
            </div>
            <div
              aria-hidden="true"
              className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            >
              <div
                style={{
                  clipPath:
                    "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                }}
                className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#3498db] to-[#58d68d] opacity-70"
              />
            </div>
            <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
              <p className="text-sm leading-6 text-gray-900">
                <strong className="font-semibold">Have Feedback?</strong>
                <svg
                  viewBox="0 0 2 2"
                  aria-hidden="true"
                  className="mx-2 inline h-0.5 w-0.5 fill-current"
                >
                  <circle r={1} cx={1} cy={1} />
                </svg>
                Let me know what you think about the AlgoBoard!
              </p>
              <a
                href="https://forms.gle/o2pdkqeoXEVV7kw78"
                className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              >
                Feedback Form <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
            <div className="flex flex-1 justify-end">
              <button
                onClick={handleDismiss}
                type="button"
                className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
              >
                <span className="sr-only">Dismiss</span>X
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
